const newsData = {
    titleEng: "news",
    titleCym: "newyddion",
    newsEng: "For all the latest news and concert information, please follow Elen on Facebook, Twitter and YouTube:",
    newsCym: "Am yr holl newyddion a gwybodaeth am y cyngherddau diweddaraf gallwch ddilyn Elen ar Facebook, Twitter a YouTube:",
    // updatesEng: [
    //     {
    //         subTitle: "Virtual Harp Ensemble",
    //         paragraph: "Something about the virtual harp ensemble, organised by Elen, and mixed by her long suffering but ultimately fanstastic husband. It got a but tonne of views on YouTube, and Elen went on the telly and everything",
    //         video: "https://www.youtube.com/embed/l1gc7qEZABU"
    //     } 
    // ],
    // updatesCym: [
    //     {
    //         subTitle: "Virtual Harp Ensemble",
    //         paragraph: "Rhywbeth am a grwp telyn cyfrifiadir. Mae elen wedi sortio allan, a Gwr hi wedi gwneud hin swn iawn, achos am cyntaf, mae telynnau yn swn fel tossach llwyr. Mae joe yn GWYCH!",
    //         video: "https://www.youtube.com/embed/l1gc7qEZABU"
    //     } 
    // ]    
}

export default newsData