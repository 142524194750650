import React from "react"
import {
  Routes,
  Route
} from "react-router-dom";
function MobileMenuTitle(props) {
    return(
        <h1 className="mobileMenuTitle">
            <Routes>
          <Route path="/biography">
            {/* Elen Hydref */}
          </Route>
          <Route path="/news">
            {/* Elen Hydref */}
          </Route>
          <Route path="/shop">
            {/* Elen Hydref */}
          </Route>
          <Route path="/teaching">
            {/* Elen Hydref */}
          </Route>
          <Route path="/contact">
            {/* Elen Hydref */}
          </Route>
          <Route path="/">
          </Route>
        </Routes>
        </h1>
    )
}

export default MobileMenuTitle