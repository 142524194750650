import React from 'react';
import {
  BrowserRouter as Router,
} from "react-router-dom";
import NavBar from "./Components/NavBar/NavBar"
import navBarData from "./Data/navBarData"
import MainContent from './Components/MainContent/MainContent';
import pictures from "./Data/pictures"
import BackgroundImg from './Components/MainContent/BackgroundImg';

class App extends React.Component{
  constructor() {
    super()
    this.state = {
      language: "eng",
      width: null,
      height: null,
      isMobileView: null,
      navBarData: navBarData,
      mobMenExpanded: false,
      imgPreCount: 0,
      imgsLoaded: false,
      backImgNo: 1
    }
    this.getWindowDimen = this.getWindowDimen.bind(this)
    this.checkPreload = this.checkPreload.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.preloadImgs = this.preloadImgs.bind(this)
    this.imgCount = this.imgCount.bind(this)
    this.setBkg = this.setBkg.bind(this)
  }

  checkPreload() {
    if(this.state.imgsLoaded !== true){
      if(this.state.imgPreCount === pictures.length){
        this.setState({imgsLoaded: true})
      }
    }
  }

  getWindowDimen() {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
      isMobileView: window.innerWidth < 768, 
      isPortrait: window.innerHeight>=window.innerWidth
    })
  }

  handleClick(id) {
    if(id==="eng" || id ==="cym"){
      this.setState({language: id})
    }
    if(id==="openMobMen"){
      this.setState({mobMenExpanded: true})
    }
    if(id==="closeMobMen"){
      this.setState({mobMenExpanded: false})
    }
  }


  imgCount(){
    this.setState((prevState) => {
      const finished = (prevState.imgPreCount+1)===pictures.length ? true : false
      return{
        imgPreCount: prevState.imgPreCount+1,
        imgsLoaded: finished
      }
    })
  }

  preloadImgs(){
    pictures.forEach((picture) => {
      const img = new Image();
      img.onload = this.imgCount()
      img.src = picture;
    });
  }

  setBkg(picNum){
    console.log("setBKG")
      this.setState({backImgNo: picNum})
  }


  componentDidMount() {
    window.addEventListener('resize', this.getWindowDimen)
    this.getWindowDimen()
    this.preloadImgs()
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.getWindowDimen)
  }
  
  render() {
    return (
      <Router>
        <BackgroundImg appState={this.state}/>
        <NavBar appState={this.state} handleClick={this.handleClick}/>
        <div className="filter"></div>
        <MainContent appState={this.state} setBkg={this.setBkg}/>
      </Router>
    )
  }
}

export default App;
