import React from "react"
import NavItem from "./NavItem"
import LanguageSelect from "./LanguageSelect"

function DesktopNavBar(props) {
    const navItems = props.appState.navBarData.map(function(item){
        console.log(props.appState.language)

        return (
            <NavItem
                key={item.itemEng}
                text={props.appState.language==="eng" ? item.itemEng : item.itemCym}
                link={item.link}
                handleClick={props.handleClick}
            />
        )
    })

    return (
        <nav className="desktopNavBar">
            <div className="navList"> 
                {navItems}
            </div> 
            <LanguageSelect 
                handleClick={props.handleClick} 
                language={props.appState.language} 
                mobileView={props.appState.isMobileView}
            />
        </nav>
    )
}

export default DesktopNavBar 