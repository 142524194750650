import React from "react"

function Hamburger(props) {
    return(
        <button 
            className="MobileMenuMidSection"
            onClick={(event) => props.handleClick("openMobMen")}
        >
            <div className="hamburger">
                <div className="hamburgerSq">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </button>
    )
}

export default Hamburger