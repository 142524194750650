import React from "react"
import {Routes ,Route } from 'react-router-dom';
import Home from "./Home"
import Biography from "./Biography"
import News from "./News"
import Shop from "./Shop"
import Teaching from "./Teaching"
import Contact from "./Contact"



function MainContent(props) {
  let clName = props.appState.isMobileView ? "mainContentM" : "maincontent"
  let hgt = props.appState.height;
  let styl = {
    // height: hgt
  }
    return (
      <div className={clName} style={styl}>
        <Routes>
          <Route path="/biography" element={
            <Biography 
              setBkg={props.setBkg}
              appState={props.appState} 
            />}
          />
          
          <Route path="/news" element={
            <News 
              setBkg={props.setBkg}
              appState={props.appState} 
            />}
          />

          <Route path="/shop" element={ 
            <Shop 
              setBkg={props.setBkg}
              appState={props.appState} 
            />}
          />
    
          <Route path="/teaching" element={ 
            <Teaching 
              setBkg={props.setBkg}
              appState={props.appState} 
            />}
          />

          <Route path="/contact" element={
            <Contact 
              setBkg={props.setBkg}
              appState={props.appState} 
            />}
          />

          <Route path="/"  element={
            <Home  
              setBkg={props.setBkg}
              appState={props.appState} 
            />}
          />
        </Routes>
      </div>
    )
  }

export default MainContent