import React from "react"
import DesktopTitle from "./DesktopTitle"
import MobileTitle from './MobileTitle'
import SocialLinks from './SocialLinks'
import Copyright from './Copyright'
import Credit from './Credit'

function Home(props) {
  if(props.appState.backImgNo !== 1){
    props.setBkg(1)
  }
    const makeTitle = props.appState.isMobileView ? <MobileTitle /> : <DesktopTitle />
    return( 
      <div>
        {makeTitle}
        <div className="homeFooter">
          <SocialLinks inline={false}/>
        </div>
        <div className="creditHome">
          <Copyright/>
          <Credit appState={props.appState}/>
        </div>
      </div>
    )
  }

export default Home