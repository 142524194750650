import React from "react"
import Copyright from "./Copyright"
import Credit from "./Credit"
import IconSocialLinks from "./IconSocialLinks"

function Footer(props){

    const midSection = props.hideIcons ?  "" : <IconSocialLinks/>

    return(
        <div className="footer">
            <div className="footerSec">
                <Copyright/>
            </div>
            <div className="footerSec">
                {midSection}
            </div>
            <div className="footerSec">
                <Credit appState={props.appState}/>
            </div>
        </div>
    )
}

export default Footer