import React from "react"
import IconLink from "./IconLink"

function IconSocialLinks(props) {
    return(
        <div className="IconSocialLinks">
            <IconLink 
                webLink="https://www.facebook.com/elenhydref/"
                imgLink={`${require('../../Images/Facebook.png')}`}
                alt="Facebook"
            />
            <IconLink 
                webLink="https://twitter.com/elenhydref"
                imgLink={`${require('../../Images/Twitter.png')}`}
                alt="Twitter"
            />
            <IconLink 
                webLink="https://www.youtube.com/channel/UCi-h0lcI324f8-Z-2sQwXhg"
                imgLink={`${require('../../Images/YouTube.png')}`}
                alt="YouTube"
            />
        </div>
    )
} 

export default IconSocialLinks