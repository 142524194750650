import React from "react"

function IconLink(props) {
    return(
        <div className="iconLink">
            <a href={props.webLink}>
                <img src={props.imgLink} alt={props.alt}/>
            </a>
        </div> 
    )
}

export default IconLink