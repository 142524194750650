import React from "react"

function Copyright(props) {

    const date = new Date();
    const year = date.getFullYear();
    const style = {
        textAlign: "left"
    }

    return(
        <p style={style}>
            &#169;{year}&nbsp;Elen Hydref
        </p>
    )
} 

export default Copyright