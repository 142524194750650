import React from "react"

function BackgroundImg(props){

    let imgSuffix = props.appState.isPortrait ? "Mob.jpg" : ".jpg"
    let imgPreSuffix = props.appState.isPortrait ? "Mobs.jpg" : "s.jpg"
    let multiplier = 1
    let width = props.appState.width
    let height = props.appState.height
    let newHeight = 0
    let newWidth = 0
    let imgWid = 2097
    let imgHgt = 1400
    let leftShift = 0
    let imgLink
    let imgNo= props.appState.backImgNo

    if(props.appState.isPortrait){
        multiplier = height / imgHgt
        newWidth = imgWid*multiplier
        newHeight = newWidth
        if(newWidth<width){
            multiplier = width / imgWid
            newWidth = imgWid*multiplier
            newHeight = newWidth
        }
        leftShift = width-newWidth
    } else {
        //get ratio of window width -> pic width
        multiplier = width / imgWid
        newWidth = imgWid*multiplier
        newHeight = imgHgt*multiplier
        //check to see if the height fails to cover screen
        if(newHeight<height){
            multiplier = height / imgHgt
            newWidth = imgWid*multiplier
            newHeight = imgHgt*multiplier
        }
    }
    let styleP = {
        width: newWidth,
        height: newHeight,
        // top: '0%',
        // left: leftShift
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    }
    let styleL = {
        width: newWidth,
        height: newHeight,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    }
    let imgStyle = props.appState.isPortrait ? styleP : styleL
    
    console.log(props.appState.imgsLoaded)
    if(props.appState.imgsLoaded){
      imgLink = `${require('../../Images/Elen'+ imgNo + imgSuffix)}`
    } else {
      imgLink = `${require('../../Images/Small/Elen'+ imgNo + imgPreSuffix)}`
    }

  
    return( 
        <img src={imgLink} alt="" className='backImg' style={imgStyle} />
      )
}


export default BackgroundImg