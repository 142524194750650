import React from 'react'

function LanguageSelect(props) {
    const engStyle = {
        color: props.language==="eng" ? "#777777" : "var(--navCol2)",
        width: props.mobileView ? "51px" : "73px"

    }
    const cymStyle = {
        color: props.language==="cym" ? "#777777" : "var(--navCol2)",
        width: props.mobileView ? "51px" : "80px"
    }
    
    return(
    <div className="languageSelect">
        <div className="languageSelectLinksL">
            <button 
                onClick={(event) => props.handleClick("eng")}
                style={engStyle}
            >
                {props.mobileView ? "eng" : "english"}
            </button>
        </div>

        <div className="languageSelectLinksR">
            <button  
                onClick={(event) => props.handleClick("cym")}
                style={cymStyle}
            >
                {props.mobileView ? "cym" : "cymraeg"}
            </button>
        </div>
    </div> 
    )
}

export default LanguageSelect