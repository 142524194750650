const pictures = [
    "./Images/Elen1.jpg",
    "./Images/Elen2.jpg",
    "./Images/Elen3.jpg",
    "./Images/Elen4.jpg",
    "./Images/Elen5.jpg",
    "./Images/Elen6.jpg",
    "./Images/Elen1Mob.jpg",
    "./Images/Elen2Mob.jpg",
    "./Images/Elen3Mob.jpg",
    "./Images/Elen4Mob.jpg",
    "./Images/Elen5Mob.jpg",
    "./Images/Elen6Mob.jpg"
]

export default pictures