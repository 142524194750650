import React from 'react'
import {
    Link
  } from "react-router-dom";

function NavItem(props) {
   
    return (
        <div>
            <Link to={props.link} className="navItem" onClick={(event) => props.handleClick("closeMobMen")}>
                {props.text}
            </Link>
        </div>
    )
}

export default NavItem