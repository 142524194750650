import React from "react"

function CloseMobMenu(props) {
    return(
        <button onClick={(event) => props.handleClick("closeMobMen")}>
            <div className="hamburger">
                <div className="closeMenuSq">
                    <h1>&#x2715;</h1>
                </div>
            </div>
        </button>
    )
}

export default CloseMobMenu