import React from "react"

function MobileTitle() {
    const style1 = {
        fontSize: "6.4em",
        letterSpacing: "0.5em",
        paddingLeft: "0.5em",
        paddingBottom: "0"
    }
    const style2 = {
        fontSize: "5em",
        letterSpacing: "0.3em",
        paddingLeft: "0.3em",
        paddingBottom: "15px"
    }
    return( 
      <div className="homeTitle">
        <h1 style={style1}>
            Elen
        </h1>
        <h1 style={style2}>
            Hydref
        </h1>
      </div>
    )
  }

export default MobileTitle