const contactData = {
    titleEng: "contact",
    titleCym: "cysylltu",
    contactEng: [
        {
            text: "Elen is available through the Musicians Answering Service:"
        }
    ],
    contactCym: [
        {
            text: ""
        }
    ],
    deetsEng: [
        {
            phone: "Telephone",
            info: "01306 500022"
        },
        {
            email: "Email",
            info: "mas@maslink.co.uk"
        }
    ],
    deetsCym: [
        {
            phone: "Rhif Ffôn",
            info: "01306 500022"
        },
        {
            email: "Ebost",
            info: "mas@maslink.co.uk"
        }
    ]
}

export default contactData