const teachingData = {
    titleEng: "teaching",
    titleCym: "dysgu",
    contentEng: [
        {
            subTitle: "Subjects",
            paragraph: "Pedal harp (all levels)"
        },
        {
            paragraph: "Lever harp (up to grade 5)"
        },
        {
            paragraph: "Music theory (grade 1-5)"
        },
        {},
        {
            subTitle: "Availability & Prices",
            paragraph: "Lessons are currently only available online via Zoom and cost £35 an hour or £17.50 for 30 minutes. For more information or to book a lesson you can get in touch with Elen here:",
            bLinkUrl: "https://www.musicteachers.co.uk/teacher/517e0980a7d3e2a3462e",
            bLinkText: "Elen on www.musicteachers.co.uk"
        }
    ],
    contentCym: [
        {
            subTitle: "Pynciau",
            paragraph: "Telyn Bedal (pob lefel)"
        },
        {
            paragraph: "Telyn Geltaidd (i fyny at gradd 5)"
        },
        {
            paragraph: "Theori Cerdd (graddau 1-5)"
        },
        {},
        {
            subTitle: "Argaeledd a Phrisiau",
            paragraph: "Mae gwersi ar y funud ar lein yn unig trwy zoom ac yn costio £35 yr awr neu £17.50 am 30 munud. Am fwy o fanylion neu i drefnu gwersi gallwch gysylltu ac Elen yma:",
            bLinkUrl: "https://www.musicteachers.co.uk/teacher/517e0980a7d3e2a3462e",
            bLinkText: "Elen ar www.musicteachers.co.uk"
        }
    ]
}

export default teachingData

