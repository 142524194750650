import React from "react"

function PageTitle(props) {
    let style
    if(props.appState.isMobileView){
      style = {
        fontSize: "2.8em",
        letterSpacing: "0.5em",
        paddingLeft: "0.5em",
        paddingBottom: "0"
      }
    } else {
      style = {
        fontSize: "4em",
        letterSpacing: "0.5em",
        paddingLeft: "0.5em",
        paddingBottom: "0"
      }
    }
    

    return(
      <div className="pageTitle"> 
        <h1 style={style}>
          {props.title}
        </h1>
      </div>
    )
  }

export default PageTitle