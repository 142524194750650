import React from "react"
import PageTitle from "./PageTitle"
import teachingData from "../../Data/teachingData.js"
import Section from "./Section"
import Footer from './Footer'

function Teaching(props) {
  if(props.appState.backImgNo !== 5){
    props.setBkg(5)
  }
    const title = props.appState.language==="eng" ? teachingData.titleEng : teachingData.titleCym

    const data = props.appState.language==="eng" ? teachingData.contentEng : teachingData.contentCym
    const content = data.map(function(item){
      return(
          <Section
            appState={props.appState}
            contents={item}
          />
        )
      }
    )

    return( 
      <div className="pageContents">
        <PageTitle title={title} appState={props.appState}/> 
        <div className="pageContent">
          {content}
        </div>
        <Footer appState={props.appState}/>
      </div>
    )
  }

export default Teaching