import React from "react"
import PageTitle from "./PageTitle"
import shopData from "../../Data/shopData"
import Footer from './Footer'
import SqImgLink from "./SqImgLink"

function Shop(props) {
  if(props.appState.backImgNo !== 3){
    props.setBkg(3)
  }
    const title = props.appState.language==="eng" ? shopData.titleEng : shopData.titleCym
 
    return( 
      <div className="pageContents">
        <PageTitle title={title} appState={props.appState}/> 
        <div className="pageContent">
          <div className="shopContent">
            <SqImgLink webLink="https://elenhydref.bandcamp.com/album/yn-y-cyfnod-clo-2"
                  imgLink={`${require('../../Images/ElenAlbum2.jpg')}`}
                  alt="Yn y Cyfnod Clo on Bandcamp"
            />
            <SqImgLink webLink="https://elenhydref.bandcamp.com/merch/elen-hydref-cd-jewel-case"
                  imgLink={`${require('../../Images/ElenAlbum1.jpg')}`}
                  alt="Buy Elen's Debut CD on Bandcamp"
            />
          </div>
        </div> 
        <Footer appState={props.appState}/>
      </div>
    )
  }

export default Shop