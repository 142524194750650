import React from "react"
import PageTitle from "./PageTitle"
import biogData from "../../Data/BiographyData"
import Section from "./Section"
import Footer from "./Footer"

function Biography(props) {
  if(props.appState.backImgNo !== 2){
    props.setBkg(2)
  }
    const title = props.appState.language==="eng" ? biogData.titleEng : biogData.titleCym

    const data = props.appState.language==="eng" ? biogData.contentEng : biogData.contentCym
    const content = data.map(function(item){
      return(
        <Section 
        appState={props.appState}
        contents={item}

        />
      )
      }
    )

    return( 
      <div className="pageContents">
        <PageTitle title={title}  appState={props.appState}/>
        <div className="pageContent">
          {content}
        </div>  
        <Footer appState={props.appState}/>
      </div>
    )
  }

export default Biography