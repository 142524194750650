import React from "react"

function DeetLink(props) {
    const sectionClass = "sectionMob";
    const headingClass = "sectionHeadingMob";
    const paraClass = "sectionParagraphMob";
    
    let deetType
    let deetText
    if('phone' in props.deet){
        deetType = "tel: "
        deetText = props.deet.phone
    } 
    if('email' in props.deet){
        deetType = "mailto: "
        deetText = props.deet.email
    }

    const deetLink = <a className={paraClass} href={deetType+props.deet.info}>{props.deet.info}</a> 

    return(
        <div className={sectionClass}>
            <div className={headingClass}>
                <h2>
                    {deetText}
                </h2>
            </div>
            <div>
                {deetLink}        
            </div>
        </div>
    )
}

export default DeetLink
