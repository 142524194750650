const biogData = {
    titleEng: "Biography",
    titleCym: "bywgraffiad",
    contentEng: [
        {
            subTitle: "Orchestra",
            paragraph: "Welsh harpist Elen Hydref enjoys a career of freelancing with some of the UK and Norway’s finest orchestras. She regularly works with orchestras including Royal Ballet Sinfonia, Welsh National Opera, Royal Opera House Orchestra, Bergen Philharmonic Orchestra, Norwegian National Opera and Ballet Orchestra, Arctic Philharmonic Orchestra, City of Birmingham Symphony Orchestra, Royal Philharmonic Concert Orchestra and Aurora Orchestra. During the 2016/17 season Elen worked as substitute principal harpist with the Norwegian National Opera and Ballet Orchestra in Oslo. She has been part of many recordings with Bergen Philharmonic Orchestra and has also played in many live broadcasts including Aurora Orchestra’s stunning memorised production of Berlioz’s Symphonie Fantastique at the Proms in 2019. As a student she was a member of both the National Youth Orchestra of Wales and the National Youth Orchestra of Great Britain."
        },
        {
            subTitle: "Teaching",
            paragraph: "As well as her orchestral career, Elen enjoys teaching. Alongside her private teaching, she regularly tutores on the National Children’s Orchestra of Great Britain courses and has also tutored several times at the Wales Harp Festival in Caernarfon. During the 2020 lockdown Elen created The Virtual Harp Ensemble, bringing 80 harpists together to perform her arrangement of David of the White Rock to raise money and awareness for theatres and orchestras struggling due to Covid 19. The video is available to view on Elen’s YouTube channel."
        },
        {
            subTitle: "Solo",
            paragraph:"As a soloist Elen has been performing at venues across the UK and Europe since an early age. At the age of 12 she was chosen to perform in the ABRSM National Concert for High Achievers at the Royal Opera House. Since then she has given many recitals and performances, including at the European Harp Symposium, the World Harp Congress, ‘Harp Masters’ Switzerland and at the 40th Anniversary Festival of Camac Harps in Ancenis, France. Her debut self titled solo CD was released with Sain Records in 2012."
        },
        {
            subTitle: "Education",
            paragraph:"Having studied with Dylan Wyn Rowlands and Elinor Bennett in North Wales, Elen began her studies at the Purcell School, followed by the Royal Academy of Music where she studied with Skaila Kanga and Catherine White. She graduated with a BMus First Class Honours degree followed by an MA with distinction and a DipRAM. In 2017 Elen was made an Associate of the Royal Academy of Music. Whilst still at school Elen won many prizes in various Eisteddfodau and Gwyliau Cerdd Dant as well as winning second prize in the Texaco Young Musician of Wales competition 2003 and being awarded the Walter Todds Prize in the 2004 BBC Young Musician of the Year competition. She later went on to win the Camac Harp Competition and the Nansi Richards Scholarship."
        }
    ],
    contentCym: [
        {
            subTitle: "Cerddorfa",
            paragraph: "Mae’r delynores Elen Hydref yn mwynhau gyrfa o chwarae gyda rhai o gerddorfeydd gorau Prydain a Norwy. Mae hi’n gweithio’n aml gyda cherddorfeydd megis Royal Ballet Sinfonia, Cwmni Opera Cenedlaethol Cymru, Royal Opera House Orchestra, Bergen Philharmonic Orchestra, Cerddorfa Opera a Ballet Genedlaethol Norwy, Arctic Philharmonic Orchestra, City of Birmingham Symphony Orchestra, Royal Philharmonic Concert Orchestra ac Aurora Orchestra. Yn ystod tymor 2016/17 bu Elen yn gweithio fel prif delynores dros dro gyda Cherddorfa Cwmni Opera a Ballet Cenedlaethol Norwy yn Oslo. Mae wedi bod yn rhan o sawl recordiad gyda Cherddorfa Ffilharmoneg Bergen ac hefyd wedi perfformio mewn llawer o ddarlleniadau byw gan gynnwys perfformiad anhygoel Aurora Orchestra o Symphonie Fantastique gan Berlioz o gof yn y Proms yn 2019. Pan yn ifanc bu Elen yn aelod o Gerddorfa Ieuenctid Genedlaethol Cymru a Cherddorfa Ieuenctid Genedlaethol Prydain."
        },
        {
            subTitle: "Dysgu",
            paragraph: "Yn ogystal a’i gyrfa gerddorfaol mae Elen yn mwynhau dysgu. Mae hi’n dysgu’n breifat, yn tiwtro’n rheolaidd ar gyrsiau Cerddorfa Genedlaethol Plant Prydain ac hefyd wedi bod yn diwtor sawl gwaith yng Nghŵyl Delynau Cymru yng Nghaernarfon. Yn ystod cyfnod clo 2020 creodd Elen Yr Ensemble Telyn Rhithiol, gan ddod ag 80 o delynorion at ei gilydd i berfformio ei haddasiad o Dafydd y Garreg Wen ar gyfer codi arian ac ymwybyddiaeth at theatrau a cherddorfeydd sydd wedi eu taro’n galed gan effaith Covid 19. Mae’r fideo ar gael i’w wylio ar sianel YouTube Elen."
        },
        {
            subTitle: "Unawdol",
            paragraph:"Fel unawdwr mae Elen wedi bod yn perfformio ar draws Prydain ac Ewrop ers pan oedd yn ifanc. Pan oedd yn ddeuddeg cafodd ei dewis i berfformio yng Nghyngerdd Cenedlaethol ABRSM i’r rhai a gyflawnodd yn uchel yn y Tŷ Opera Brenhinol. Ers hynny mae wedi rhoi llawer o berfformiadau a chyngherddau gan gynnwys yn y Symposium Telyn Ewropeiadd, y Cyngres Telyn Byd, ‘Harp Masters’ yn y Swistir ac yng Ngwyl Benblwydd 40 Telynau Camac yn Ancenis, Ffrainc. Mi gafodd ei CD cyntaf ei ryddhau efo Recordiau Sain yn 2012."
        },
        {
            subTitle: "Addysg",
            paragraph:"Ar ôl astudio gyda Dylan Wyn Rowlands ac Elinor Bennett yng Nghogledd Cymru, dechreuodd Elen ei hastudiaethau yn Ysgol Purcell ac yna yn yr Academi Gerdd Frenhinol lle bu’n astudio gyda Skaila Kanga a Catherine White. Graddiodd gyda Gradd BMus dosbarth cyntaf gydag anrhydedd ac yna MA gydag anrhydedd a DipRAM. Yn 2017 cafodd Elen ei gwneud yn ‘Associate of The Royal Academy of Music’. Tra’n dal yn yr ysgol ennillodd Elen lawer o wobrau mewn sawl Eisteddfod a Gwyl Cerdd Dant yn ogystal ag ennill ail wobr yng nghystadleuaeth Cerddor Ifanc Cymru Texaco yn 2003 ac ennill gwobr Walter Todds yng nghystadleuaeth Cerddor ifanc y Flwyddyn y BBC yn 2004. Aeth ymlaen i ennill Cystadleuaeth Telyn Camac ac Ysgoloriaeth Nansi Richards."
        }
    ],
}

export default biogData

