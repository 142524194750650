import React from "react"
import MobileNavBar from "./MobileNavBar"
import DesktopNavBar from "./DesktopNavBar"

let styleC = {
  opacity: 0.85
}
let styleO = {
  opacity: 0.98
}
function NavBar(props) {
    const chosenNavBar = (() => {
        if (props.appState.isMobileView){
          let style = props.appState.mobMenExpanded ? styleO : styleC
          return <MobileNavBar appState={props.appState} handleClick={props.handleClick} style={style}/>
        }
        else
          return <DesktopNavBar appState={props.appState} handleClick={props.handleClick}/>
      })();
    
    return (
        <div className="navBar"> 
            {chosenNavBar}
        </div> 
    )
}

export default NavBar