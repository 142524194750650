import React from "react"

function GForm(){
    return(
        <div className="gForm">
            <iframe title="gForm" src="https://docs.google.com/forms/d/e/1FAIpQLSeCQkwUFM_1g7ERgGOzjTrdxgET5WvnNwVfnstZ517RW1eMNQ/viewform?embedded=true" width="640" height="942"    frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
        </div>
    )
}

export default GForm
