import React from "react"
import PageTitle from "./PageTitle"
import newsData from "../../Data/newsData"
import TextOnlySection from "./TextOnlySection"
import SocialLinks from "./SocialLinks"
import Footer from './Footer'

function News(props) {
  if(props.appState.backImgNo !== 4){
    props.setBkg(4)
  }
  const title = props.appState.language==="eng" ? newsData.titleEng : newsData.titleCym

  const newsHeading = props.appState.language==="eng" ? newsData.newsEng : newsData.newsCym
    
    return( 
      <div className="pageContents">
        <PageTitle title={title} appState={props.appState}/> 
        <div className="pageContent">
          <TextOnlySection
            appState = {props.appState}
            content = {newsHeading} 
          />
          <SocialLinks inline={true}/>
        </div> 
        <Footer appState={props.appState} hideIcons={true}/>
      </div>
    )
  }

export default News