import React from "react"
import NavItem from "./NavItem"
import MobileMenuTitle from "./MobileMenu/MobileMenuTitle"
import Hamburger from "./MobileMenu/Hamburger"
import CloseMobMenu from "./MobileMenu/CloseMobMenu";
import LanguageSelect from "./LanguageSelect"

function MobileNavBar(props) {
    const hamburgerState = (()=>{
        if(props.appState.mobMenExpanded)
            return <CloseMobMenu handleClick={props.handleClick}/>
        else
            return <Hamburger handleClick={props.handleClick}/>
    })();

    const navItems = props.appState.navBarData.map(function(item){
        console.log(props.appState.language)

        return (
            <NavItem
                key={item.itemEng}
                text={props.appState.language==="eng" ? item.itemEng : item.itemCym}
                link={item.link}
                handleClick={props.handleClick}
            />
        )
    })

    const showHideMenu = {
        display: props.appState.mobMenExpanded ? "flex" : "none" 
    }
    return (
        <nav className="mobileNavBar" style={props.style}>
            <MobileMenuTitle />
            {hamburgerState}
            <div className="mobileLangContainer">
                <LanguageSelect 
                    handleClick={props.handleClick} 
                    language={props.appState.language} 
                    mobileView={props.appState.isMobileView}
                />
            </div>
            <div 
                className="mobileMenuItems"
                style = {showHideMenu}
            >
                {navItems}
            </div>
        </nav>
    )
}

export default MobileNavBar