import React from "react"

function Section(props) {
    const sectionClass = props.appState.isMobileView ? "sectionMob" : "section";
    const headingClass = props.appState.isMobileView ? "sectionHeadingMob" : "sectionHeading";
    const paraClass = props.appState.isMobileView ? "sectionParagraphMob" : "sectionParagraph";
    
    const hideOrNot = 'subTitle' in props.contents ? "false" : "true"
    const bottomLink = 'bLinkUrl' in props.contents ? <div className="secBLink"><a  href={props.contents.bLinkUrl}>{props.contents.bLinkText}</a></div> : "";


    return(
        <div className={sectionClass}>
            <div className={headingClass}>
                <h2 aria-hidden={hideOrNot}>
                    {props.contents.subTitle}
                </h2>
            </div>
            <div>
                <p className={paraClass}>
                    {props.contents.paragraph}    
                </p>
                {bottomLink}
            </div>
        </div>
    )
}

export default Section
