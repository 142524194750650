import React from "react"

function DesktopTitle() {
  
  const style = {
    fontSize: "4.7em",
    letterSpacing: "0.5em",
    paddingLeft: "0.5em",
    paddingBottom: "50px"
  }
    
    return( 
      <div className="homeTitle" fontSize="4.7em">
        <h1 style={style}>
            Elen Hydref
        </h1>
      </div>
    )
  }

export default DesktopTitle 