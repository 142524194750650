import React from "react"
import PageTitle from "./PageTitle"
import contactData from  "../../Data/contactData"
import TextOnlySection from "./TextOnlySection"
import DeetLink from "./DeetLink"
import Footer from "./Footer"
import GForm from "./GForm"


function Contact(props) {
  if(props.appState.backImgNo !== 6){
    props.setBkg(6)
  }
    const title = props.appState.language==="eng" ? contactData.titleEng : contactData.titleCym
    const text = props.appState.language ==="eng" ? contactData.contactEng : contactData.contactCym
    const deets = props.appState.language==="eng" ? contactData.deetsEng : contactData.deetsCym

    const textSect = text.map(function(item){
        return(
          <TextOnlySection
            appState = {props.appState}
            content = {item.text} 
          />
        )
      }
    )

    const deetsComps = deets.map(function(item){
        return(
          <DeetLink
            appState={props.appState}
            deet={item}
          />
        )
      }
    )

    return (
      <div className="pageContents">
        <PageTitle title={title} appState={props.appState}/>
        <div className="pageContent">
          {textSect}
          {deetsComps}
          <GForm />
        </div> 
        <Footer appState={props.appState}/>
      </div>
    )
  }

export default Contact