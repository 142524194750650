import React from "react"

function Credit(props) {

    
    const style = {
        textAlign: "right"
    }
    const text = props.appState.language==="eng" ? "photos and web design by" : "lluniau a dyluniad gwefan gan"
    
    return(
        <p style={style}>
            {text}&nbsp;<a href="http://www.joewrightmusic.co.uk/">joe wright music</a>
        </p>
        
    )
} 

export default Credit