const navBarData = [
        {
            itemEng: "home",
            itemCym: "hafan",
            background: "Elen1",
            link: "/"
        },
        {
            itemEng: "biography",
            itemCym: "bywgraffiad",
            background: "Elen2",
            link: "/biography"
        },
        {
            itemEng: "news",
            itemCym: "newyddion",
            background: "Elen3",
            link: "/news"
        },
        {
            itemEng: "shop",
            itemCym: "siop",
            background: "Elen4",
            link: "/shop"
        },
        {
            itemEng: "teaching",
            itemCym: "dysgu",
            background: "Elen5",
            link: "/teaching"
        },
        {
            itemEng: "contact",
            itemCym: "cysylltu",
            background: "Elen6",
            link: "/contact"
        },
    ]

export default navBarData