import React from "react"

function TextOnlySection(props) {
    const sectionClass = props.appState.isMobileView ? "textOnlyMob" : "textOnly";
    return(
        <div className={sectionClass}>
            <p>
                {props.content}
            </p>
        </div>
    )
}

export default TextOnlySection